<template>
  <div id="customerService">
    <el-dialog
      title="修改客服"
      width="660px"
      :visible.sync="customerServiceDialogVisible"
      :close-on-click-modal="false"
      @close="customerServiceDialogClose"
    >
      <el-form
        ref="customerServiceFormRef"
        :model="customerServiceForm"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="类型">
              <el-input v-model="type" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="二维码">
              <el-upload
                ref="uploadRef"
                action=""
                list-type="picture-card"
                :http-request="handleHttp"
                :on-remove="handleRemove"
                :on-preview="handlePreview"
                :file-list="fileList"
                :limit="1"
              >
                <i class="el-icon-plus" />
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="customerServiceForm.remarks"
                placeholder="请输入备注"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="customerServiceDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="customerServiceFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-dialog id="image" width="540px" :visible.sync="imageDialogVisible">
      <img
        width="500px"
        object-fit="contain"
        :src="imageUrl"
        alt=""
      >
    </el-dialog>
    <el-table
      :data="customerServiceList"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeightNoPage()"
    >
      <el-table-column label="类型">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 1">露营Together客服</span>
          <span v-if="scope.row.type === 2">露营群</span>
          <span v-if="scope.row.type === 3">活动客服</span>
        </template>
      </el-table-column>
      <el-table-column label="二维码">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.pic"
            style="width: 100px; height: 100px"
            fit="contain"
            :src="baseUrl + scope.row.pic"
            :preview-src-list="[baseUrl + scope.row.pic]"
          />
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { updateCustomerService, selectCustomerServiceList } from '@/api/main/customerService'
import { upload2 } from '@/api/main/file'

export default {
  data () {
    return {
      customerServiceDialogVisible: false,
      customerServiceForm: {
        id: '',
        pic: '',
        remarks: ''
      },
      customerServiceList: [],
      type: '',
      baseUrl: 'https://oss-quye.huahuihongxin.com/',
      imageDialogVisible: false,
      imageUrl: '',
      fileList: []
    }
  },
  created () {
    selectCustomerServiceList().then(res => {
      this.customerServiceList = res.list
    })
  },
  methods: {
    customerServiceDialogClose () {
      this.fileList = []
    },
    customerServiceFormSubmit () {
      this.$refs.customerServiceFormRef.validate(async valid => {
        if (valid) {
          this.customerServiceForm.pic = this.fileList.map(item => item.name).join(',')
          await updateCustomerService(this.customerServiceForm)
          this.customerServiceList = (await selectCustomerServiceList()).list
          this.customerServiceDialogVisible = false
        }
      })
    },
    handleUpdate (index, row) {
      this.customerServiceDialogVisible = true
      this.customerServiceForm.id = row.id
      if (row.type === 1) {
        this.type = '露营Together客服'
      } else {
        this.type = '露营群'
      }
      if (row.pic) {
        const picUrl = row.pic.split(',')
        this.fileList = []
        for (let i = 0; i < picUrl.length; i++) {
          this.fileList.push({
            name: picUrl[i],
            url: this.baseUrl + picUrl[i]
          })
        }
      }
      this.customerServiceForm.remarks = row.remarks
    },
    handleHttp (file) {
      const data = new FormData()
      data.append('file', file.file)
      upload2(data).then(res => {
        this.fileList.push({
          name: res,
          url: this.baseUrl + res,
          uid: file.file.uid
        })
      })
    },
    handleRemove (file, fileList) {
      this.fileList = fileList
    },
    handlePreview (file) {
      this.imageUrl = file.url
      this.imageDialogVisible = true
    }
  }
}
</script>

<style>
#customerService #image .el-dialog__body {
  padding: 20px;
}
</style>
