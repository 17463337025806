import axios from '@/common/axios'
import qs from 'qs'

export function updateCustomerService (data) {
  return axios({
    method: 'put',
    url: '/qrCode/update',
    data: qs.stringify(data)
  })
}

export function selectCustomerServiceList () {
  return axios({
    method: 'get',
    url: '/qrCode/list'
  })
}
